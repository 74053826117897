<template>
  <div class="md-layout">
    <!-- <loading :active="app.showLoader.value" :can-cancel="true"></loading> -->
    <div class="md-layout-item md-size-90">
      <h3 class="md-title m-0">Master Data</h3>
    </div>
    <div class="md-layout-item md-size-100 pt-2">
      <select-input
        name="MasterType"
        :options="
          MasterTypeList.value.map((x) => ({
            value: x.name,
            text: x.displayValue,
          }))
        "
        v-model="type.value"
        style="margin: 0; max-width: 250px"
      />
    </div>
    <div style="min-width: 100%">
      <globe-activity-slim-card>
        <loading :active="app.showLoader.value" :can-cancel="true"></loading>
        <template v-slot:header>
          <div class="card-icon">
            <i class="fa fa-globe"></i>
          </div>
          <h4 class="title">{{ type.value }}</h4>
        </template>
        <template v-slot:content>
          <div class="justify-content-end d-flex">
            <md-button
              toolTip="Add New MasterData"
              @click="handleAddClick()"
              class="md-primary"
              >Add
            </md-button>
          </div>
          <div>
            <MasterData-list-table
              @edit-MasterData="onEditMasterData"
            ></MasterData-list-table>
          </div>
        </template>
      </globe-activity-slim-card>
    </div>
    <md-dialog :md-active.sync="showDialog">
      <div class="modal-size-medium">
        <manage-MasterData-page
          :masterName="type.value"
          :MasterData="selectedItem"
          :lookupMasterID="lookupMasterID.value"
        ></manage-MasterData-page>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import GlobeActivitySlimCard from "../../../../components/Globechek/Cards/GlobeActivitySlimCard.vue";
import ManageMasterDataPage from "../../MasterData/manage-MasterData/ManageMasterDataPage.vue";
import { withManageMasterDataHandler } from "../../../../handlers/module-handlers/MasterData/MasterDataHandler";
import MasterDataListTable from "./MasterDataListTable.vue";
export default {
  components: {
    GlobeActivitySlimCard,
    MasterDataListTable,
    ManageMasterDataPage: withManageMasterDataHandler(ManageMasterDataPage),
  },
  inject: ["featureDetails", "MasterTypeList", "type", "lookupMasterID", "app"],
  methods: {
    handleAddClick() {
      this.selectedItem = { id: "" };
      this.openDialog();
    },
    closeDialog() {
      this.showDialog = false;
    },
    openDialog() {
      this.showDialog = true;
    },
    onEditMasterData(item) {
      this.selectedItem = item;
      this.openDialog();
    },
  },
  created() {},
  data() {
    return {
      showDialog: false,
      selectedItem: { id: "" },
    };
  },
  provide() {
    return {
      closeDialog: this.closeDialog,
    };
  },
};
</script>

<style scoped></style>
