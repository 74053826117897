export class MasterDataRequest {
  LookupId = 0;
  LookupMasterId = 0;
  name = "";
  Value = "";
  DisplayValue = "";
  CreatedAt = "2019-10-14 16:39:07";
  CreatedBy = "";
  UpdatedAt = "2019-10-14 16:39:07";
  UpdatedBy = "";
}
