<template>
  <manage-page-form-layout title="Masterdata Information">
    <!-- <loading :active="app.showLoader.value" :can-cancel="true"></loading> -->
    <template v-slot:content>
      <ValidationListener
        @getIsValid="(isValid) => (MasterDataForm.isValid = isValid)"
      >
        <div class="form-vertical">
          <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <text-input
                label="Name *"
                placeholder="Name *"
                icon="abc"
                v-model="MasterDataForm.postData.name"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
        </div>
        <div class="form-vertical">
          <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <text-input
                label="Display value *"
                placeholder="Display value *"
                icon="abc"
                v-model="MasterDataForm.postData.DisplayValue"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
        </div>
        <div class="form-vertical">
          <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <text-input
                label="Value *"
                placeholder="Value *"
                icon="abc"
                v-model="MasterDataForm.postData.Value"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
        </div>
      </ValidationListener>
    </template>
  </manage-page-form-layout>
</template>
<script>
import ManagePageFormLayout from "../../../../components/layouts/manage-page-layout/ManagePageFormLayout.vue";
export default {
  components: { ManagePageFormLayout },
  inject: ["MasterDataForm", "master"],
};
</script>
