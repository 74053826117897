import { mapActions } from "vuex";
import { Success, Error } from "../../../models/SwalMessages";
import { MasterDataRequest } from "../../../models/MasterData";
export default {
  inject: ["showError", "showSuccess", "getLookupList", "isDuplicated"],
  data() {
    return {
      MasterDataForm: {
        postData: new MasterDataRequest(),
      },
      manageMasterData: {
        isSaving: false,
      },
    };
  },
  methods: {
    ...mapActions("master", [
      "addLookupValue",
      "getLookupValueByID",
      "updateLookupValue",
    ]),
    loadMasterData(item) {
      this.getLookupValueByID(item.lookupId).then((res) => {
        this.MasterDataForm.postData.name = res.name;
        this.MasterDataForm.postData.DisplayValue = res.displayValue;
        this.MasterDataForm.postData.LookupId = res.lookupId;
        this.MasterDataForm.postData.LookupMasterId = res.lookupMasterId;
        this.MasterDataForm.postData.Value = res.value;
      });
    },
    saveMasterData(callback) {
      if (this.isDuplicated(this.MasterDataForm.postData.DisplayValue)) {
        var error = new Error();
        error.text = "Master Data already exists";
        this.showError(error);
        callback();
      } else {
        this.addLookupValue({
          request: this.MasterDataForm.postData,
          masterName: this.manageMasterData.masterName,
        })
          .then(() => {
            var success = new Success();
            success.text = "Saved Successfully";
            this.showSuccess(success);
            this.getLookupList();
            callback();
          })
          .catch((error) => {
            var errorMsg = new Error();
            error.text = error;
            this.showError(errorMsg);
          });
      }
    },
    editMasterData(callback) {
      let id = this.MasterDataForm.postData.LookupId;
      let request = this.MasterDataForm.postData;
      this.updateLookupValue({
        id,
        request,
        masterName: this.manageMasterData.masterName,
      })
        .then(() => {
          var success = new Success();
          success.text = "Updated Successfully";
          this.showSuccess(success);
          this.getLookupList();
          callback();
        })
        .catch((error) => {
          var errorMsg = new Error();
          error.text = error;
          this.showError(errorMsg);
        });
    },
  },
  provide() {
    return {
      MasterDataForm: this.MasterDataForm,
      manageMasterData: this.manageMasterData,
    };
  },
};
