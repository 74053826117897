<template>
  <div>
    <div class="modal-header">
      {{ title }}
    </div>
    <div class="modal-content scroll">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
export default {
  props: ["title"],
};
</script>
