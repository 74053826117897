<template>
  <div>
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <MasterData-form />
    <manage-MasterData-actions />
  </div>
</template>
<script>
import ManageMasterDataActions from "./ManageMasterDataActions.vue";
import MasterDataForm from "./MasterDataForm.vue";
export default {
  inject: ["MasterDataForm", "manageMasterData", "loadMasterData", "app"],
  components: {
    MasterDataForm,
    ManageMasterDataActions,
  },
  props: {
    MasterData: {
      type: Object,
      default() {
        return {
          id: "",
        };
      },
    },
    lookupMasterID: {
      type: Number,
      default: 0,
    },
    masterName: {
      type: String,
    },
  },
  created() {
    if (this.MasterData.lookupId) {
      this.MasterDataForm.postData.id = this.MasterData.id;
      this.manageMasterData.MasterDataId = this.MasterData.id;
      this.loadMasterData(this.MasterData);
    } else {
      this.MasterDataForm.postData.LookupMasterId = this.lookupMasterID;
    }
    this.manageMasterData.masterName = this.masterName;
  },
  watch: {
    masterName: function () {
      this.manageMasterData.masterName = this.masterName;
    },
  },
};
</script>
