<template>
  <div class="md-content md-table-content">
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <md-table
      class="
        globe-activity
        md-table-global-sales
        MasterData
        responsive
        hideheader
      "
    >
      <md-table-row>
        <md-table-head>Name</md-table-head>
        <md-table-head>Display Value</md-table-head>
        <md-table-head>Value</md-table-head>
        <md-table-head>Action</md-table-head>
      </md-table-row>
      <md-table-row v-for="item in lookupValues.value" :key="item.id">
        <md-table-cell>{{ item.name }}</md-table-cell>
        <md-table-cell>{{ item.displayValue }}</md-table-cell>
        <md-table-cell>{{ item.value }}</md-table-cell>
        <md-table-cell md-label="Action">
          <md-icon @click.native="handleEditClick(item)">edit</md-icon>
          <md-icon class="delete-icon" @click.native="handleDeleteClick(item)"
            >delete</md-icon
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
export default {
  inject: ["lookupValues", "list", "listInput", "handleDeleteClick", "app"],
  data() {
    return {};
  },
  methods: {
    handleEditClick(item) {
      this.$emit("edit-MasterData", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  // padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.MasterData.responsive td:nth-of-type(1):before {
  content: "Name";
}
.MasterData.responsive td:nth-of-type(2):before {
  content: "Display Value";
}
.MasterData.responsive td:nth-of-type(3):before {
  content: "Value";
}
.MasterData.responsive td:nth-of-type(4):before {
  content: "Action";
}
</style>
