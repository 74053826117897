import MasterService from "../../../services/common-services/MasterService";
import ManageMasterDataService from "../../../services/module-services/MasterData/ManageMasterDataService";
import NavigationService from "../../../services/common-services/NavigationService";

const ManageMasterDataHandler = {
  mixins: [ManageMasterDataService, MasterService, NavigationService],
  methods: {
    async submit(callback) {
      if (!(await this.MasterDataForm.isValid())) return;
      if (this.MasterDataForm.postData.LookupId) {
        this.editMasterData(callback);
      } else {
        this.saveMasterData(callback);
      }
    },
    navigateToMasterData() {
      this.navigateTo("MasterData");
    },
  },
  provide() {
    return {
      handleSave: this.submit,
      loadMasterData: this.loadMasterData,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ManageMasterDataHandler;

export const withManageMasterDataHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ManageMasterDataHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageMasterDataHandler>`,
    components: { ManageMasterDataHandler, WrappedComponent },
  };
};
